<template>
  <validation-provider :rules="rules"
                       :vid="vid"
                       :name="name"
                       :mode="validationInteractionMode"
                       v-slot="{ errors, classes }"
                       slim>
    <div class="base-phone-number"
         :class="getClasses(classes)">
      <label class="label"
             v-if="label"
             @click="focus">{{ label }}</label>

      <vue-tel-input :id="id"
                     :name="name"
                     class="input"
                     ref="input"
                     :value="value"
                     :autocomplete="autocomplete"
                     :disabled="disabled"
                     :placeholder="placeholder"
                     @country-changed="countryChanged"
                     @input="onInput"
                     @blur="onBlur" />

      <div class="error"
           v-show="showErrors && errors.length > 0">
        {{ errors[0] }}
      </div>

      <div class="description"
           v-if="description">
        {{ description }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    id: {
      required: false,
      type: String
    },

    vid: {
      required: false,
      type: String
    },

    value: {
      required: true
    },

    name: {
      required: false,
      type: String
    },

    label: {
      required: false,
      type: String
    },

    description: {
      required: false,
      type: String
    },

    rules: {
      required: false,
      type: [String, Object],
      default: ''
    },

    validationInteractionMode: {
      required: false,
      type: String
    },

    showErrors: {
      required: false,
      type: Boolean,
      default: true
    },

    placeholder: {
      required: false,
      type: String,
      default: null
    },

    autocomplete: {
      required: false,
      type: String,
      default: 'off'
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  methods: {
    getClasses (validationClasses) {
      return {
        ...validationClasses,
        'has-validation-rules': !!this.rules,
        'disabled': this.disabled
      }
    },

    onInput (value) {
      this.$emit('input', value)
    },

    onBlur () {
      this.$emit('blur', this.value)
    },

    focus () {
      this.$refs.input.focus()
    },

    countryChanged (country) {
      this.$emit('countryChanged', country)
    }
  }
}
</script>

<style lang="scss" scoped>
  .base-phone-number {
    position: relative;
    margin-bottom: 20px;

    .input {
      // width: 100%;
      // padding-right: 10px;
      // height: 40px;

      // background: #fff;
      // border: 1px solid map-get($colors, gray-3);
      // border-radius: 4px;
      // outline: none;

      // font-size: 16px;
      // color: map-get($colors, gray-1);
      // transition: all 0.2s;

      // box-shadow: none;

      width: 100%;
      padding: 0 14px 0 0;
      height: 44px;

      background: #fff;

      background: #FFFFFF;
      border-radius: 6px;

      outline: none;

      font-size: 14px;
      font-weight: 500;
      transition: all 0.2s;

      border: 1px solid map-get($colors, border);
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

      &:hover,
      &:focus {
        box-shadow: 0px 3px 5px rgba(101, 81, 224, 0.15);
        border-color: map-get($colors, purple-1);
      }

      ::v-deep {
        .vti__dropdown {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        .vti__dropdown-item {
          cursor: pointer;
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
        }

        .vti__flag {
          margin-right: 12px;
        }
      }

      &:focus, &:focus-within {
        border: 1px solid map-get($colors, purple-1);
      }
    }

    .label {
      display: block;
      margin-bottom: 6px;
      color: map-get($colors, gray-1);
      font-size: 14px;
      font-weight: 600;
    }

    .description {
      margin-top: 4px;
      font-size: 14px;
      line-height: 21px;
      color: map-get($colors, gray-1);
    }

    &.has-validation-rules {
      &.invalid .input {
        border-color: map-get($colors, red-2);
      }

      &.valid .input {
        border-color: map-get($colors, success);
      }
    }

    &.disabled .input {
      background-color: #f5f5f5;
    }

    .error {
      margin-top: 4px;
      color: map-get($colors, red-2);
    }
  }
</style>
