<template>
  <v-modal :name="name"
           :width="width"
           :min-height="minHeight"
           :height="height"
           @before-open="$emit('before-open', $event)">
    <div class="modal-header">
      <slot name="header"></slot>
    </div>

    <div class="modal-body">
      <slot></slot>
    </div>

    <div class="modal-footer">
      <slot name="footer"></slot>
    </div>
  </v-modal>
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String
    },

    width: {
      required: false
    },

    height: {
      required: false,
      type: String,
      default: 'auto'
    },

    minHeight: {
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .vm--overlay {
    height: 100% !important;
  }
  ::v-deep .vm--modal {
    display: flex;
    flex-direction: column;
    background-color: map-get($colors, background);
    border-radius: 6px;

    @media (max-width: 767px) {
      left: 0 !important;
      width: 80% !important;
      margin: 0 10%;
    }

    .modal-header {
      //background: #F7F9FC;
      border-bottom: 1px solid #d6d9da;
      padding: 20px;

      font-size: 18px;
      line-height: 20px;

      color: #888799;
    }

    .modal-body {
      flex: 1 0;
      padding: 20px;
    }

    .modal-footer {
      padding: 20px;

      &:empty {
        display: none;
      }
    }
  }
</style>
